<template>
  <div>
    <CourseDetail />
    <UML />
    <OOD />
    <ObjectOriented />
    <UMLDetail />
    <EightWrites />
  </div>
</template>
<script>
import CourseDetail from "@/components/course/coursesComponents/courseDetail";
import UML from "@/components/course/coursesComponents/UML";
import OOD from "@/components/course/coursesComponents/OOD";
import ObjectOriented from "@/components/course/coursesComponents/ObjectOriented";
import UMLDetail from "@/components/course/coursesComponents/UMLDetail";
import EightWrites from "@/components/course/coursesComponents/eightWrites";
export default {
  name: "NewCourse",
  components: {
    CourseDetail,
    UML,
    OOD,
    ObjectOriented,
    UMLDetail,
    EightWrites,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
</style>